import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class Mainpage extends Component {
  container = {
    padding: 12,
    background: '#5581a6'
  }
  container_2 = {
    padding: 12,
    background: '#935167'
  }
  render() {
    return (
      <div>
        <h1>Программа обучения</h1>
        <div class="container">
          <div class="card">
            <div class="box">
              <div class="content">
                <h2>Me</h2>
                <h3 style={this.container}>Профиль</h3>
                <p>Данные о вашем прохождении курса</p>
                <button href="#">Статистика</button>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <div class="content">
                <h2>Me</h2>
                <h3 style={this.container_2}>Вступительные уроки</h3>
                <p>Краткий рассказ о проекте</p>
                <Link to="/additions_lesson" state={{ media: {
                  img: [],
                  video: [
                    { title: 'Промо', video: 'before_video/before_1.mp4' }, 
                    { title: 'Мышление должника', video: 'before_video/before_2.mp4' },
                    { title: 'Начало выплат по долгам', video: 'before_video/before_3.mp4' },
                    { title: 'Проблемные кредиторы', video: 'before_video/before_4.mp4' },
                    { title: 'Негатив от кредиторов', video: 'before_video/before_5.mp4' },
                    { title: 'Список долгов', video: 'before_video/before_6.mp4' },
                    { title: 'Финансовая программа', video: 'before_video/before_7.mp4' }
                  ]
                }}}><button href="#">Посмотреть</button></Link>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <div class="content">
                <h2>00</h2>
                <h3>Вводный Урок</h3>
                <Link to="/about_lesson" state={{ content: [
                  {
                    text: 'Рассказ ведущего о том, как он влез в долги',
                    ref: '/lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Как не занимать деньги, если нечего есть?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Как самостоятельно закрывать долги?',
                    ref: '/additions_lesson',
                  },
                ], video: 'introduction_lesson.mp4', media: {
                  img: [],
                  video: [
                    { title: 'Как мне не занимать деньги, если мне нечего поесть', video: 'add_1.mp4' }, 
                    { title: 'Как мне самостоятельно закрывать все долги', video: 'add_2.mp4' }
                  ]
                } }}><button>Что будет в уроке</button></Link>
                <Link to="/lesson" state={{ video: 'introduction_lesson.mp4', media: {
                  img: [],
                  video: [
                    { title: 'Как мне не занимать деньги, если мне нечего поесть', video: 'add_1.mp4' }, 
                    { title: 'Как мне самостоятельно закрывать все долги', video: 'add_2.mp4' }
                  ]
                } }}><button>Посмотреть урок</button></Link>
                <Link to="/additions_lesson" state={{ media: {
                  img: [],
                  video: [
                    { title: 'Как мне не занимать деньги, если мне нечего поесть', video: 'add_1.mp4' }, 
                    { title: 'Как мне самостоятельно закрывать все долги', video: 'add_2.mp4' }
                  ]
                }}}><button>Доп. материалы</button></Link>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <div class="content">
                <h2>01</h2>
                <h3>Первый Урок</h3>
                <Link to="/about_lesson" state={{ content: [
                  {
                    text: '2 приницпа, без которых долги никогда не исчезнут',
                    ref: '/lesson',
                  },
                  {
                    text: 'Чек лист по избавлению от долгов',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Чек лист диагностика закредитованности',
                    ref: '/additions_lesson',
                  },
                ], video: 'first_lesson.mp4', media: {
                  img: [
                    { title: 'Чек лист по избавлению от долгов', img: 'list_1.jpeg' }, 
                    { title: 'Чек лист диагностика закредитованности', img: 'list_2.jpg' }
                  ],
                  video: []
                } }}><button>Что будет в уроке</button></Link>
                <Link to="/lesson" state={{ video: 'first_lesson.mp4', media: {
                  img: [
                    { title: 'Чек лист по избавлению от долгов', img: 'list_1.jpeg' }, 
                    { title: 'Чек лист диагностика закредитованности', img: 'list_2.jpg' }
                  ],
                  video: []
                } }}><button>Посмотреть урок</button></Link>
                <Link to="/additions_lesson" state={{ media: {
                  img: [
                    { title: 'Чек лист по избавлению от долгов', img: 'list_1.jpeg' }, 
                    { title: 'Чек лист диагностика закредитованности', img: 'list_2.jpg' }
                  ],
                  video: []
                }}}><button>Доп. материалы</button></Link>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <div class="content">
                <h2>02</h2>
                <h3>Второй Урок</h3>
                <Link to="/about_lesson" state={{ content: [
                  {
                    text: 'Финансовое планирование',
                    ref: '/lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что делать, когда коллекторы угрожают выездом по адресу?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что делать, когда нет денег на бензин или ремонт машины угрожают?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что делать, когда нет денег на лекарства?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Чек лист как договориться с коллектором',
                    ref: '/additions_lesson',
                  },
                ], video: 'second_lesson.mp4', media: {
                  img: [
                    { title: 'Чек лист как договориться с коллектором', img: 'list_3.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Что делать, когда коллекторы угрожают выездом по адресу?', video: 'add_3.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, когда нет денег на бензин или ремонт машины угрожают?', video: 'add_4.mp4' },
                    { title: 'Ответ на вопрос: Что делать, когда нет денег на лекарства?', video: 'add_5.mp4' }
                  ]
                } }}><button>Что будет в уроке</button></Link>
                <Link to="/lesson" state={{ video: 'second_lesson.mp4', media: {
                  img: [
                    { title: 'Чек лист как договориться с коллектором', img: 'list_3.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Что делать, когда коллекторы угрожают выездом по адресу?', video: 'add_3.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, когда нет денег на бензин или ремонт машины угрожают?', video: 'add_4.mp4' },
                    { title: 'Ответ на вопрос: Что делать, когда нет денег на лекарства?', video: 'add_5.mp4' }
                  ]
                } }}><button>Посмотреть урок</button></Link>
                <Link to="/additions_lesson" state={{ media: {
                  img: [
                    { title: 'Чек лист как договориться с коллектором', video: 'list_3.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Что делать, когда коллекторы угрожают выездом по адресу?', video: 'add_3.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, когда нет денег на бензин или ремонт машины угрожают?', video: 'add_4.mp4' },
                    { title: 'Ответ на вопрос: Что делать, когда нет денег на лекарства?', video: 'add_5.mp4' }
                  ]
                } }}><button>Доп. материалы</button></Link>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <div class="content">
                <h2>03</h2>
                <h3>Третий Урок</h3>
                <Link to="/about_lesson" state={{ content: [
                  {
                    text: 'Как не брать в долг',
                    ref: '/lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что делать, когда коллекторы угрожают вашей жизни и здоровью?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что делать, когда коллекторы звонят моим близким?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что делать, когда постоянно звонят коллекторы?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Чек лист как не брать в долг на продукты',
                    ref: '/additions_lesson',
                  },
                ], video: 'third_lesson.mp4', media: {
                  img: [
                    { title: 'Чек лист как не брать в долг на продукты', img: 'list_4.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Что делать, когда коллекторы угрожают вашей жизни и здоровью?', video: 'add_6.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, когда коллекторы звонят моим близким?', video: 'add_7.mp4' },
                    { title: 'Ответ на вопрос: Что делать, когда постоянно звонят коллекторы?', video: 'add_8.mp4' }
                  ]
                } }}><button>Что будет в уроке</button></Link>
                <Link to="/lesson" state={{ video: 'third_lesson.mp4', media: {
                  img: [
                    { title: 'Чек лист как не брать в долг на продукты', img: 'list_4.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Что делать, когда коллекторы угрожают вашей жизни и здоровью?', video: 'add_6.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, когда коллекторы звонят моим близким?', video: 'add_7.mp4' },
                    { title: 'Ответ на вопрос: Что делать, когда постоянно звонят коллекторы?', video: 'add_8.mp4' }
                  ]
                } }}><button>Посмотреть урок</button></Link>
                <Link to="/additions_lesson" state={{ media: {
                  img: [
                    { title: 'Чек лист как не брать в долг на продукты', img: 'list_4.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Что делать, когда коллекторы угрожают вашей жизни и здоровью?', video: 'add_6.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, когда коллекторы звонят моим близким?', video: 'add_7.mp4' },
                    { title: 'Ответ на вопрос: Что делать, когда постоянно звонят коллекторы?', video: 'add_8.mp4' }
                  ]
                } }}><button>Доп. материалы</button></Link>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <div class="content">
                <h2>04</h2>
                <h3>Четвертый Урок</h3>
                <Link to="/about_lesson" state={{ content: [
                  {
                    text: 'Инструменты финасового контроля и планирования',
                    ref: '/lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Нужно ли оплачивать продление займа?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что делать, когда коллектор говорит, что на вас подадут в суд?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Как избавиться от долгов, имея кредитные карты и займы?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Шаблон месячного бюджета',
                    ref: '/additions_lesson',
                  },
                ], video: 'four_lesson.mp4', media: {
                  img: [
                    { title: 'Шаблон месячного бюджета', img: 'list_5.jpeg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Нужно ли оплачивать продление займа?', video: 'add_9.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, когда коллектор говорит, что на вас подадут в суд?', video: 'add_10.mp4' },
                    { title: 'Ответ на вопрос: Как избавиться от долгов, имея кредитные карты и займы?', video: 'add_11.mp4' }
                  ]
                } }}><button>Что будет в уроке</button></Link>
                <Link to="/lesson" state={{ video: 'four_lesson.mp4', media: {
                  img: [
                    { title: 'Шаблон месячного бюджета', img: 'list_5.jpeg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Нужно ли оплачивать продление займа?', video: 'add_9.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, когда коллектор говорит, что на вас подадут в суд?', video: 'add_10.mp4' },
                    { title: 'Ответ на вопрос: Как избавиться от долгов, имея кредитные карты и займы?', video: 'add_11.mp4' }
                  ]
                } }}><button>Посмотреть урок</button></Link>
                <Link to="/additions_lesson" state={{ media: {
                  img: [
                    { title: 'Шаблон месячного бюджета', img: 'list_5.jpeg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Нужно ли оплачивать продление займа?', video: 'add_9.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, когда коллектор говорит, что на вас подадут в суд?', video: 'add_10.mp4' },
                    { title: 'Ответ на вопрос: Как избавиться от долгов, имея кредитные карты и займы?', video: 'add_11.mp4' }
                  ]
                } }}><button>Доп. материалы</button></Link>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <div class="content">
                <h2>05</h2>
                <h3>Пятый Урок</h3>
                <Link to="/about_lesson" state={{ content: [
                  {
                    text: 'Инструменты финасового контроля и планирования',
                    ref: '/lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что делать, когда должен МФО, а не друзьям?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Стоит ли перезванивать на незнакомые номера?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что такое антиколлектор?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Какие вопросы можно решить с кредитором',
                    ref: '/additions_lesson',
                  },
                ], video: 'five_lesson.mp4', media: {
                  img: [
                    { title: 'Какие вопросы можно решить с кредитором', img: 'list_6.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Нужно ли оплачивать продление займа?', video: 'add_12.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, когда коллектор говорит, что на вас подадут в суд?', video: 'add_13.mp4' },
                    { title: 'Ответ на вопрос: Как избавиться от долгов, имея кредитные карты и займы?', video: 'add_14.mp4' }
                  ]
                } }}><button>Что будет в уроке</button></Link>
                <Link to="/lesson" state={{ video: 'five_lesson.mp4', media: {
                  img: [
                    { title: 'Какие вопросы можно решить с кредитором', img: 'list_6.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Нужно ли оплачивать продление займа?', video: 'add_12.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, когда коллектор говорит, что на вас подадут в суд?', video: 'add_13.mp4' },
                    { title: 'Ответ на вопрос: Как избавиться от долгов, имея кредитные карты и займы?', video: 'add_14.mp4' }
                  ]
                } }}><button>Посмотреть урок</button></Link>
                <Link to="/additions_lesson" state={{ media: {
                  img: [
                    { title: 'Какие вопросы можно решить с кредитором', img: 'list_6.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Нужно ли оплачивать продление займа?', video: 'add_12.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, когда коллектор говорит, что на вас подадут в суд?', video: 'add_13.mp4' },
                    { title: 'Ответ на вопрос: Как избавиться от долгов, имея кредитные карты и займы?', video: 'add_14.mp4' }
                  ]
                } }}><button>Доп. материалы</button></Link>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <div class="content">
                <h2>06</h2>
                <h3>Шестой Урок</h3>
                <Link to="/about_lesson" state={{ content: [
                  {
                    text: 'Платные инструменты закрытия долгов',
                    ref: '/lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Как мне платить ипотеку с плохой кредитной историей, из-за неуплаты долгов?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что делать, если МФО списывает средства автоматически?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Как списать долги МФО, чтобы сохранилась ипотечная квартира?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'О чем помнить, выбирая банкротство?',
                    ref: '/additions_lesson',
                  },
                ], video: 'six_lesson.mp4', media: {
                  img: [
                    { title: 'О чем помнить, выбирая банкротство?', img: 'list_7.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Как мне платить ипотеку с плохой кредитной историей, из-за неуплаты долгов?', video: 'add_15.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, если МФО списывает средства автоматически?', video: 'add_16.mp4' },
                    { title: 'Ответ на вопрос: Как списать долги МФО, чтобы сохранилась ипотечная квартира?', video: 'add_17.mp4' }
                  ]
                } }}><button>Что будет в уроке</button></Link>
                <Link to="/lesson" state={{ video: 'six_lesson.mp4', media: {
                  img: [
                    { title: 'О чем помнить, выбирая банкротство?', img: 'list_7.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Как мне платить ипотеку с плохой кредитной историей, из-за неуплаты долгов?', video: 'add_15.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, если МФО списывает средства автоматически?', video: 'add_16.mp4' },
                    { title: 'Ответ на вопрос: Как списать долги МФО, чтобы сохранилась ипотечная квартира?', video: 'add_17.mp4' }
                  ]
                } }}><button>Посмотреть урок</button></Link>
                <Link to="/additions_lesson" state={{ media: {
                  img: [
                    { title: 'О чем помнить, выбирая банкротство?', img: 'list_7.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Как мне платить ипотеку с плохой кредитной историей, из-за неуплаты долгов?', video: 'add_15.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, если МФО списывает средства автоматически?', video: 'add_16.mp4' },
                    { title: 'Ответ на вопрос: Как списать долги МФО, чтобы сохранилась ипотечная квартира?', video: 'add_17.mp4' }
                  ]
                } }}><button>Доп. материалы</button></Link>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <div class="content">
                <h2>07</h2>
                <h3>Седьмой Урок</h3>
                <Link to="/about_lesson" state={{ content: [
                  {
                    text: 'Платные инструменты закрытия долгов',
                    ref: '/lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Сколько денег в месяц тратить на сигареты и алкоголь?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что делать, когда арестовали счёт в банке?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Могу ли я поехать за границу, если у меня арестовали счёт в банке?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Как за полгода отдать все долги и погасить кредиты?',
                    ref: '/additions_lesson',
                  },
                ], video: 'seven_lesson.mp4', media: {
                  img: [
                    { title: 'Как за полгода отдать все долги и погасить кредиты?', img: 'list_8_1.jpg' }, 
                    { title: '', img: 'list_8_2.jpg' }, 
                    { title: '', img: 'list_8_3.jpg' }, 
                    { title: '', img: 'list_8_4.jpg' }, 
                    { title: '', img: 'list_8_5.jpg' }, 
                    { title: '', img: 'list_8_6.jpg' }, 
                    { title: '', img: 'list_8_7.jpg' }, 
                    { title: '', img: 'list_8_8.jpg' }, 
                    { title: '', img: 'list_8_9.jpg' }, 
                    { title: '', img: 'list_8_10.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Как мне платить ипотеку с плохой кредитной историей, из-за неуплаты долгов?', video: 'add_18.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, если МФО списывает средства автоматически?', video: 'add_19.mp4' },
                    { title: 'Ответ на вопрос: Как списать долги МФО, чтобы сохранилась ипотечная квартира?', video: 'add_20.mp4' }
                  ]
                } }}><button>Что будет в уроке</button></Link>
                <Link to="/lesson" state={{ video: 'seven_lesson.mp4', media: {
                  img: [
                    { title: 'Как за полгода отдать все долги и погасить кредиты?', img: 'list_8_1.jpg' }, 
                    { title: '', img: 'list_8_2.jpg' }, 
                    { title: '', img: 'list_8_3.jpg' }, 
                    { title: '', img: 'list_8_4.jpg' }, 
                    { title: '', img: 'list_8_5.jpg' }, 
                    { title: '', img: 'list_8_6.jpg' }, 
                    { title: '', img: 'list_8_7.jpg' }, 
                    { title: '', img: 'list_8_8.jpg' }, 
                    { title: '', img: 'list_8_9.jpg' }, 
                    { title: '', img: 'list_8_10.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Как мне платить ипотеку с плохой кредитной историей, из-за неуплаты долгов?', video: 'add_18.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, если МФО списывает средства автоматически?', video: 'add_19.mp4' },
                    { title: 'Ответ на вопрос: Как списать долги МФО, чтобы сохранилась ипотечная квартира?', video: 'add_20.mp4' }
                  ]
                } }}><button>Посмотреть урок</button></Link>
                <Link to="/additions_lesson" state={{ media: {
                  img: [
                    { title: 'Как за полгода отдать все долги и погасить кредиты?', img: 'list_8_1.jpg' }, 
                    { title: '', img: 'list_8_2.jpg' }, 
                    { title: '', img: 'list_8_3.jpg' }, 
                    { title: '', img: 'list_8_4.jpg' }, 
                    { title: '', img: 'list_8_5.jpg' }, 
                    { title: '', img: 'list_8_6.jpg' }, 
                    { title: '', img: 'list_8_7.jpg' }, 
                    { title: '', img: 'list_8_8.jpg' }, 
                    { title: '', img: 'list_8_9.jpg' }, 
                    { title: '', img: 'list_8_10.jpg' }, 
                  ],
                  video: [
                    { title: 'Ответ на вопрос: Как мне платить ипотеку с плохой кредитной историей, из-за неуплаты долгов?', video: 'add_18.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, если МФО списывает средства автоматически?', video: 'add_19.mp4' },
                    { title: 'Ответ на вопрос: Как списать долги МФО, чтобы сохранилась ипотечная квартира?', video: 'add_20.mp4' }
                  ]
                } }}><button>Доп. материалы</button></Link>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <div class="content">
                <h2>08</h2>
                <h3>Восьмой Урок</h3>
                <Link to="/about_lesson" state={{ content: [
                  {
                    text: 'Управление своей карьерой',
                    ref: '/lesson',
                  },
                  {
                    text: 'Заповеди финансового освобождения',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что делать, если кредиторы пишут мне на работу?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Что делать, если зарплаты не хватает на выплату долгов и остальные нужды?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Что делать, когда не можешь совмещать несколько подработок?',
                    ref: '/additions_lesson',
                  },
                ], video: 'eight_lesson.mp4', media: {
                  img: [],
                  video: [
                    { title: 'Заповеди финансового освобождения', video: 'add_21_0.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, если кредиторы пишут мне на работу?', video: 'add_21.mp4' },
                    { title: 'Ответ на вопрос: Что делать, если зарплаты не хватает на выплату долгов и остальные нужды?', video: 'add_22.mp4' },
                    { title: 'Что делать, когда не можешь совмещать несколько подработок?', video: 'add_23.mp4' }
                  ]
                } }}><button>Что будет в уроке</button></Link>
                <Link to="/lesson" state={{ video: 'eight_lesson.mp4', media: {
                  img: [],
                  video: [
                    { title: 'Заповеди финансового освобождения', video: 'add_21_0.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, если кредиторы пишут мне на работу?', video: 'add_21.mp4' },
                    { title: 'Ответ на вопрос: Что делать, если зарплаты не хватает на выплату долгов и остальные нужды?', video: 'add_22.mp4' },
                    { title: 'Что делать, когда не можешь совмещать несколько подработок?', video: 'add_23.mp4' }
                  ]
                } }}><button>Посмотреть урок</button></Link>
                <Link to="/additions_lesson" state={{ media: {
                  img: [],
                  video: [
                    { title: 'Заповеди финансового освобождения', video: 'add_21_0.mp4' }, 
                    { title: 'Ответ на вопрос: Что делать, если кредиторы пишут мне на работу?', video: 'add_21.mp4' },
                    { title: 'Ответ на вопрос: Что делать, если зарплаты не хватает на выплату долгов и остальные нужды?', video: 'add_22.mp4' },
                    { title: 'Что делать, когда не можешь совмещать несколько подработок?', video: 'add_23.mp4' }
                  ]
                } }}><button>Доп. материалы</button></Link>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <div class="content">
                <h2>09</h2>
                <h3>Девятый Урок</h3>
                <Link to="/about_lesson" state={{ content: [
                  {
                    text: 'Личные финансы',
                    ref: '/lesson',
                  },
                  {
                    text: 'Что такое финансовая подушка безопасности и как её рассчитать?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Сколько денег откладывать от зарплаты на будущее?',
                    ref: '/additions_lesson',
                  },
                  {
                    text: 'Ответ на вопрос: Как правильно хранить свои сбережения?',
                    ref: '/additions_lesson',
                  },
                ], video: 'nine_lesson.mp4', media: {
                  img: [],
                  video: [
                    { title: 'Что такое финансовая подушка безопасности и как её рассчитать?', video: 'add_24.mp4' }, 
                    { title: 'Ответ на вопрос: Сколько денег откладывать от зарплаты на будущее?', video: 'add_25.mp4' },
                    { title: 'Ответ на вопрос: Как правильно хранить свои сбережения?', video: 'add_26.mp4' },
                  ]
                } }}><button>Что будет в уроке</button></Link>
                <Link to="/lesson" state={{ video: 'nine_lesson.mp4', media: {
                  img: [],
                  video: [
                    { title: 'Что такое финансовая подушка безопасности и как её рассчитать?', video: 'add_24.mp4' }, 
                    { title: 'Ответ на вопрос: Сколько денег откладывать от зарплаты на будущее?', video: 'add_25.mp4' },
                    { title: 'Ответ на вопрос: Как правильно хранить свои сбережения?', video: 'add_26.mp4' },
                  ]
                } }}><button>Посмотреть урок</button></Link>
                <Link to="/additions_lesson" state={{ media: {
                  img: [],
                  video: [
                    { title: 'Что такое финансовая подушка безопасности и как её рассчитать?', video: 'add_24.mp4' }, 
                    { title: 'Ответ на вопрос: Сколько денег откладывать от зарплаты на будущее?', video: 'add_25.mp4' },
                    { title: 'Ответ на вопрос: Как правильно хранить свои сбережения?', video: 'add_26.mp4' },
                  ]
                } }}><button>Доп. материалы</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Mainpage