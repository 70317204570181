import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function AdditionsLesson() {
  const location = useLocation();
  const { media } = location.state;
  console.log(media)

  return (
      <div class='list-about'>
        <h1>Дополнительные материалы</h1>
        {/* { media.img.map(el =>(
          <div></div>
        )) } */}
        { media.video.map(el =>(
          <div>
            <h2 className='video-title'>{ el.title }</h2> 
            <video src={ 'assets/additionals_video/' + el.video } controls>
              <p>
                  Ваш браузер не поддерживает HTML5 видео
              </p>
            </video> 
          </div>
        )) }
        { media.img.map(el =>(
          <div>
            <h2 className='video-title'>{ el.title }</h2> 
            <img src={ 'assets/img/' + el.img } alt='#' />
          </div>
        )) }
        <Link to="/"><button>На главную страницу</button></Link>
      </div>
  )
}

export default AdditionsLesson