import React from 'react'
import { Link, useLocation } from 'react-router-dom';


function Lesson() {
  const location = useLocation();
  const { video, media } = location.state;

  return (
      <div>
          <h1>Главный урок</h1>
          <video src={'assets/video/' + video} controls>
              <p>
                  Ваш браузер не поддерживает HTML5 видео
              </p>
          </video>      
          {/* <embed src="/assets/pdf/history.pdf" width="800px" height="2100px" /> */}
          <Link to="/"><button>На главную страницу</button></Link>
          <Link to="/additions_lesson" state={{ media: media }}><button>Дополнительные материалы к уроку</button></Link>

      </div>
  )
}

export default Lesson;