import React from 'react'
import { Link, useLocation } from 'react-router-dom'


function AboutLesson() {
  const location = useLocation();
  const { content } = location.state;
  const { video } = location.state;
  const { media } = location.state;

  return (
    <div class='list-about'>
    <h1>Что будет в уроке?</h1>
    <ol class="circle-list">
        { content.map(el =>(
          <Link to={ el.ref } state={{ video: video, media: media }}>
            <li>
              <h2>{ el.text }</h2>
            </li>
          </Link>
        )) }
    </ol>
    <Link to="/"><button>На главную страницу</button></Link>
  </div>
  )
}

export default AboutLesson