import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'

import Lesson from './pages/lessons/Lesson'
import Mainpage from './pages/Mainpage'
import AboutLesson from './pages/about/AboutLesson'
import AdditionsLesson from './pages/additions/AdditionsLesson'


export class App extends Component {
  render() {
    return (
      <Routes>
        <Route path='/' element={<Mainpage />} />
        <Route path='/lesson' element={<Lesson />} />
        <Route path='/about_lesson' element={<AboutLesson />} />
        <Route path='/additions_lesson' element={<AdditionsLesson />} />
      </Routes>
    )
  }
}

export default App